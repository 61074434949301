@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';











































































































































































































































.rendimiento-combustible {
  padding-top: 50px;
  padding-bottom: 60px;

  h1 {
    margin-bottom: 50px;
    margin-top: 0px;

    @media (max-width: $breakpoint-xs) {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;

    &.label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 60px;

    thead {
      th {
        padding: 10px 16px;
        // text-align: left;
        font-size: 18px;

        @media (max-width: $breakpoint-xs) {
          font-size: 16px;
        }

        &:nth-child(odd) {
          background-color: #efefef;
        }

        &:nth-child(even) {
          background-color: #e0e0e0;
        }
      }
    }

    tbody {
      tr {
        background-color: #f7f7f7;

        &:first-child {
          td {
            font-weight: 600;

            @media (max-width: $breakpoint-xs) {
              font-size: 12px;
            }

            &:nth-child(odd) {
              background-color: #efefef;
            }

            &:nth-child(even) {
              background-color: #e0e0e0;
            }
          }
        }

        td {
          padding: 10px 16px;
          text-align: center;
          font-size: 18px;
          font-family: SuzukiPRORegular;

          @media (max-width: $breakpoint-xs) {
            padding: 10px 5px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .disclaimer {
    font-size: 10px;
    margin: 15px 0 0;
  }
}
